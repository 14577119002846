import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import Amplify from "aws-amplify";
import VueGoogleCharts from "vue-google-charts";
Vue.use(VueGoogleCharts);
Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_M8INjlfza",
    region: "ap-south-1",
    userPoolWebClientId: "6vngg6vre17lg3ucprlfbb0beu",
  },
  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint: "https://e3skdxxyrzerxnrt3u4prz4wxe.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "RESTAPI",
        endpoint: "https://zezbeec9tb.execute-api.ap-south-1.amazonaws.com/workhistory/",
      },
      {
        name: "SURECREDS",
        endpoint: "https://8l4r1qbuq4.execute-api.ap-south-1.amazonaws.com/surecreds/",
      },
    ],
  },
});
Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
