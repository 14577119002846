import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    EmailID: "",
    Password: "",
    user_type: "",
    GetCurrentUser: [],
    GetCurrentOrg: [],
    GetTeamList: [],
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_user_password: (state) => {
      return state.Password;
    },
    get_current_user_details: (state) => {
      return state.GetCurrentUser;
    },
    get_user_type: (state) => {
      return state.user_type;
    },
  },
  mutations: {
    SET_USEREMAIL(state, username) {
      state.EmailID = username;
    },
    SET_USERPASSWORD(state, Password) {
      state.Password = Password;
    },
    SET_CURRENTUSER_DETAILS(state, GetCurrentUserDetails) {
      state.GetCurrentUser = GetCurrentUserDetails;
    },
    SET_USER_TYPE(state, user_type) {
      state.user_type = user_type;
    },
  },
  actions: {},
  modules: {},
});
