import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: () => import(/* webpackChunkName: "LoginPage" */ "../views/MainHomePages/LoginPage.vue"),
    children: [
      {
        path: "/Home",
        name: "Home",
        component: () => import(/* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/Home.vue"),
      },
      {
        path: "/FAQs",
        name: "FAQs",
        component: () => import(/* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/FAQs.vue"),
      },
      {
        path: "/Terms&Conditions",
        name: "Terms&Conditions",
        component: () => import(/* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/Terms&Conditions.vue"),
      },
      {
        path: "/AboutUs",
        name: "AboutUs",
        component: () => import(/* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/AboutUs.vue"),
      },
      {
        path: "/ContactUs",
        name: "ContactUs",
        component: () => import(/* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/ContactUs.vue"),
      },
      {
        path: "/PrivacyPolicy",
        name: "PrivacyPolicy",
        component: () => import(/* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/PrivacyPolicy.vue"),
      },
      {
        path: "/Login",
        name: "Login",
        component: () => import(/* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/Login.vue"),
      },
      {
        path: "/VerificationRequest",
        name: "VerificationRequest",
        component: () => import(/* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/VerificationRequest.vue"),
      },
      {
        path: "/VerifyCredentials",
        name: "VerifyCredentials",
        component: () => import(/* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LoginPages/VerifyCredentials.vue"),
      },
    ],
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () => import(/* webpackChunkName: "LandingPage" */ "../views/MainHomePages/LandingPage.vue"),
    children: [
      {
        path: "/Users",
        name: "Users",
        component: () => import(/* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/Users.vue"),
      },
      {
        path: "/Payload",
        name: "Payload",
        component: () => import(/* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/Payload.vue"),
      },
      {
        path: "/BusinessInfo",
        name: "BusinessInfo",
        component: () => import(/* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/BusinessInfo.vue"),
      },
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/Dashboard.vue"),
      },
      {
        path: "/VerificationRequests",
        name: "VerificationRequests",
        component: () => import(/* webpackChunkName: "LoginPage" */ "../views/ChildHomePages/LandingPages/VerificationRequests.vue"),
      },
      {
        path: "/Media",
        name: "Media",
        component: () => import(/* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/Media.vue"),
      },
      {
        path: "/Templates",
        name: "Templates",
        component: () => import(/* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/Templates.vue"),
      },
      {
        path: "/MyProfile",
        name: "MyProfile",
        component: () => import(/* webpackChunkName: "LandingPage" */ "../views/ChildHomePages/LandingPages/MyProfile.vue"),
      },
    ],
  },
  {
    path: "/PaymentSuccessful",
    name: "PaymentSuccessful",
    component: () => import(/* webpackChunkName: "PaymentSuccessful" */ "../views/MainHomePages/PaymentSuccessful.vue"),
  },
  {
    path: "/ErrorRoute",
    name: "ErrorRoute",
    component: () => import(/* webpackChunkName: "ErrorRoute" */ "../views/MainHomePages/ErrorRoute.vue"),
  },
  {
    path: "/RegisterWithUs",
    name: "RegisterWithUs",
    component: () => import(/* webpackChunkName: "PaymentSuccessful" */ "../views/MainHomePages/RegisterWithUs.vue"),
  },
];
const router = new VueRouter({
  base: `${process.env.BASE_URL}`,
  routes,
});
export default router;
